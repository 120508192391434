<template>
  <div>
    <h1>System Administration</h1>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <div class="white-bg-container">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-container fluid>
            <b-row class="justify-space-arround flex-row pt-2">
              <b-col sm="12">
                <b-row>
                  <b-col
                    sm="3"
                    class="pl-0"
                  >
                    <b-form-group
                      label="Permission Set Name: "
                      label-for="name"
                    >
                      <b-form-input
                        id="Name"
                        v-model="permissionSetName"
                        type="text"
                        name="Name"
                        placeholder="Enter Name"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="3">
                    <b-form-group
                      label="User Type "
                      label-for="userType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="usertype"
                        rules="required"
                      >
                        <div v-if="editingPermissionSetObject === null">
                          <v-select
                            v-model="selectedUserTypeOption"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="userTypes"
                            placeholder="User Type"
                            name="usertype"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                        <div v-if="editingPermissionSetObject != null">
                          {{ selectedUserTypeOption.value }}
                        </div>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
          <b-container
            fluid
            style="margin-top: 7px;"
          >
            <b-row
              v-for="rootNode in activeCategories"
              :key="rootNode.UserType"
            >
              <b-col
                v-for="category in rootNode.Categories"
                :key="category.CategoryName"
                class="pr-0 pl-0"
              >
                <b-button
                  block
                  variant="primary"
                  class="categoryButton"
                  :style="
                    activeCategory === category
                      ? {
                        'background-color': '#476DB3  !important',
                        color: '#fff !important',
                      }
                      : { 'background-color': '#fff !important' }
                  "
                  @click="setActiveTreeCategory(category)"
                >
                  <span>{{ category.CategoryName }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-container>

          <b-container
            v-if="activeCategory !== null"
            style="margin-top: 20px;"
          >
            <app-collapse>
              <app-collapse-item
                v-for="(subcategory, index) in activeCategory.Subcategories"
                :key="subcategory.SubcategoryName"
                :title="subcategory.SubcategoryName"
                :is-visible="index >= 0"
              >
                <div
                  v-for="subcategoryGrouping in subcategory.SubcategoryGroupings"
                  :key="subcategoryGrouping.GroupingName"
                >
                  <div class="subgroupLabel">
                    {{ subcategoryGrouping.GroupingName }}
                  </div>
                  <b-form-checkbox
                    v-for="scope in subcategoryGrouping.PermissionScopes"
                    :key="scope.Scope"
                    v-model="scopeCheckboxBindings[scope.Scope]"
                    @input="onScopeCheckChanged(scope.Scope)"
                  >
                    {{ scope.Title }}<span v-if="scope.Description !== null && scope.Description.length > 0"> - {{ scope.Description }}</span>
                  </b-form-checkbox>
                </div>
              </app-collapse-item>
            </app-collapse>
          </b-container>
          <b-row>
            <div style="height: 25px;" />
          </b-row>
          <b-container>
            <!-- button -->
            <b-row class="flex-row pb-5">
              <b-col class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="processForm()"
                >
                  Save Permission Set
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="cancelPermissionSet()"
                >
                  Cancel
                </b-button>
              </b-col>
              <!-- submit and reset -->
            </b-row>
          </b-container>
        </b-form>
      </validation-observer>
      <!-- search input -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BBreadcrumb,
  BFormGroup,
  BButton,
  BCol,
  BRow,
  BContainer,
  BForm,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

import axios from "axios";

import store from "@/store/index";
// import { mapState } from 'vuex'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormInput,
    BFormGroup,
    BBreadcrumb,
    BButton,
    BCol,
    BRow,
    BContainer,
    vSelect,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      btnColor: "!important #476DB3",
      selectedUserTypeOption: null,
      permissionSetName: "",
      userTypes: [],
      permissions: [],
      permissionScopesTree: [],
      activeCategory: null,
      scopesList: [],
      scopeCheckboxBindings: {},
      editingPermissionSetObject: null,
      isCopy: false,
      required,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    selectedUserType: function () {
      if (this.selectedUserTypeOption === null) {
        return "";
      }
      return this.selectedUserTypeOption.value;
    },
    activeCategories: function () {
      if (this.permissionScopesTree === null) {
        return [];
      }
      return this.permissionScopesTree.filter(
        (i) => i.UserType === this.selectedUserType
      );
    },
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Users & Permissions',
          href: '/admin/users-and-permissions'
        },
        {
          text: 'Permission Sets',
          href: '/admin/users-and-permissions/permission-sets'
        },
        {
          text: this.editingPermissionSetObject ? 'Edit Permission Set' : 'Add New Permission Set',
          active: true,
        }
      ]
    }
  },
  watch: {
    scopeCheckboxBindings: function (val) {
      console.log("scope checkbox binding changed");
      console.log(val);

      var newScopesList = this.scopesList;
      for (var scope in val) {
        if (val[scope]) {
          if (!newScopesList.includes(scope)) {
            newScopesList.push(scope);
            console.log("Scope " + scope + " added");
          }
        } else {
          if (newScopesList.includes(scope)) {
            newScopesList = newScopesList.filter((s) => s !== scope);
            console.log("Scope " + scope + " removed");
          }
        }
      }
    },
    activeCategories: function (val) {
      this.activeCategory = null;
      this.selectDefaultCategory();
    },
    scopesList: function (val) {
      console.log('Scopes list changed!');
      console.log(this.scopesList);
      for (var i = 0; i < this.scopesList.length; i++) {
        var scope = this.scopesList[i];
        if (this.scopeCheckboxBindings[scope] === undefined || this.scopeCheckboxBindings[scope] !== true)
        {
          console.log('Setting scope ' + scope + ' to true');
          this.scopeCheckboxBindings[scope] = true;
        }
      }
    },
  },
  async created() {
    try {
      const response = await apiService
        .get("users/types")
        .then((res) => res.data)
        .catch((error) => error);
      const filterTypes = response.map((data) => ({
        title: data.DisplayName,
        value: data.UserType,
      }));
      this.userTypes = filterTypes;
    } catch (err) {
      this.error = err;
    }

    apiService
      .get("permissions/scopes/tree")
      .then((resp) => {
        this.permissionScopesTree = resp.data;
      });
  },
  mounted() {
    var existingPsId = this.$route.params.permissionsetid;

    if (this.$route.meta.isCopy) {
      this.isCopy = true;
    }

    if (existingPsId) {
      apiService
          .get("permissions/permissionset/" + existingPsId)
          .then((response) => {
            this.editingPermissionSetObject = response.data;
            this.scopesList = this.editingPermissionSetObject.Scopes.map(s => s.Scope);
            this.permissionSetName = this.editingPermissionSetObject.PermissionSet.Name;

            //console.log('Loading user type ' + this.editingPermissionSetObject.PermissionSet.UserType);
            var targetUserTypeOption = this.userTypes.find(x => x.value === this.editingPermissionSetObject.PermissionSet.UserType);
            
            if (targetUserTypeOption !== undefined)
            {
              this.selectedUserTypeOption = targetUserTypeOption;
              console.log('Successfully set user type option');
            }

            if (this.isCopy) {
              this.editingPermissionSetObject = null;
              this.permissionSetName = 'Copy of ' + this.permissionSetName;
            }
          });
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "InfoIcon",
            text: data,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
    onScopeCheckChanged(scope) {
      this.syncScopeCheckboxStateToScopesList(scope);
    },
    syncScopeCheckboxStateToScopesList(scope) {
      var scopeValue = this.scopeCheckboxBindings[scope];
      if (scopeValue) {
        // scope added
        if (!this.scopesList.includes(scope)) {
          this.scopesList.push(scope);
        }
      } else {
        // scope removed
        if (this.scopesList.includes(scope)) {
          this.scopesList = this.scopesList.filter((s) => s !== scope);
        }
      }
    },
    selectDefaultCategory() {
        if (this.selectedUserType) {
          console.log('selected user type has a valid value');
          if (this.activeCategory === null) {
            console.log('active category is indeed null');
            if (this.activeCategories !== null && this.activeCategories.length > 0) {
              console.log('there are active categories');
              if (this.activeCategories[0].Categories !== undefined && this.activeCategories[0].Categories !== null && this.activeCategories[0].Categories.length > 0)
              {
                this.setActiveTreeCategory(this.activeCategories[0].Categories[0]);
              }
            }
          }
        }
    },
    setActiveTreeCategory(category) {
      this.activeCategory = category;
      console.log('active tree category is now ' + category);
    },
    cancelPermissionSet() {
      this.$router.push('/admin/users-and-permissions/permission-sets')
    },
    processForm() {
      var postData = {
        Name: this.permissionSetName,
        UserType: this.selectedUserType,
        Scopes: this.scopesList
      };

      var endpoint = 'permissions/permissionset/create';
      if (this.editingPermissionSetObject !== null) {
        endpoint = 'permissions/permissionset/' + this.editingPermissionSetObject.PermissionSet.ID;
      }

      apiService
        .post(endpoint, postData)
        .then((res) => {
          if (res) {
            this.showToast(
                "success",
                "top-center",
                4000,
                'Permission Set saved'
            );
            this.$router.push('/admin/users-and-permissions/permission-sets');
          }
        })
    },
    delete() {
      return this;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.processForm();
        } else {
          console.log('Error in validation');
          this.showToast(
            "danger",
            "top-center",
            4000,
            'Validation error while trying to save permission set'
          );
        }
      });
    },
  },
};
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
.btn--add-users,
.input-group-text {
  border-radius: 0;
}

[dir] .form-control,
[dir] .vs--searchable .vs__dropdown-toggle {
  /* border: none; */
  border-radius: 0px;
  height: 2.78rem;
}
.min-vh-50 {
  min-height: 50vh;
}
.categoryButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
  border: 1px solid black;
  background-color: white !important;
  color: black !important;
}

.subgroupLabel {
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
